<script>
import Layout from "@/router/layouts/franchise";
import { api } from "@/state/services";

export default {
  locales: {
    pt: {
      Dashboard: "Painel",
    },
    es: {
      Dashboard: "Painel",
    },
  },
  components: {
    Layout,
  },
  data() {
    return {
      orders: [],
      sales: [],
    };
  },
  methods: {
    getOrders() {
      api.get("franchise/graphs/orders").then((response) => {
        if (response.data.status == "success") {
          this.orders = response.data.list;
        }
      });
    },
    getSales() {
      api.get("franchise/graphs/sales").then((response) => {
        if (response.data.status == "success") {
          this.sales = response.data.list;
        }
      });
    },
  },
  mounted() { },
};
</script>

<template>
  <Layout>
    <div class="page-title-box d-md-flex justify-content-between">
      <div>
        <h3>Painel da Franquia</h3>
        <p></p>
      </div>
    </div>

    <div class="card">
      <div class="card-body">
        Seja bem-vindo a Franquia!
      </div>
    </div>
  </Layout>
</template>
